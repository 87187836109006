module.exports = {
 pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

 siteTitle: "Chaitanya's Blog", // Navigation and Site Title
 siteTitleAlt: "Chaitanya's Blog", // Alternative Site title for SEO
 siteTitleManifest: 'ChaitanyaBlog',
 siteUrl: 'https://blog.kchaitanya.com', // Domain of your site. No trailing slash!
 siteLanguage: 'en', // Language Tag on <html> element
 siteHeadline: 'Writing and publishing content', // Headline for schema.org JSONLD
 siteBanner: '/social/banner.png', // Your image for og:image tag. You can find it in the /static folder
 favicon: 'src/favicon.png', // Your image for favicons. You can find it in the /src folder
 siteDescription: "Chaitanya's Personal Blog", // Your site description
 author: 'Chaitanya', // Author for schemaORGJSONLD
 siteLogo: '/social/logo.png', // Image for schemaORGJSONLD

 // siteFBAppID: '123456789', // Facebook App ID - Optional
 userTwitter: '@gangaraju4u', // Twitter Username - Optional
 ogSiteName: "Chaitanya's Personal Blog", // Facebook Site Name - Optional
 ogLanguage: 'en_US', // Facebook Language

 // Manifest and Progress color
 // See: https://developers.google.com/web/fundamentals/web-app-manifest/
 themeColor: '#3498DB',
 backgroundColor: '#2b2e3c',
};
